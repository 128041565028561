@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.CostEstimator {
  background-color: $grey;
  margin: 1.5rem 0;
  overflow: hidden;
  text-align: center;

  :global(.Button) {
    margin: 0 auto;
  }

  :global(.ButtonSet) {
    border-color: $blue !important;
  }

  @mixin above $medium {
    text-align: left;

    :global(.Button) {
      float: right;
      margin: unset;
    }

    :global(.ButtonSet) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  @mixin above 1200px {
    :global(.ButtonSet) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }


  :global(.rangeslider__label-item):last-child {
    left: 0 !important;
    margin-left: 0.3rem;
  }

  :global(.rangeslider__label-item):first-child {
    margin-left: 0;
  }

  :global(.rangeslider__handle) {
    background-color: $blue;
    border: none;
    border-radius: 29px;
    box-shadow: none;
    height: 29px;
    outline: none;
    width: 29px;

    &:after {
      display: none;
    }
  }

  :global(.rangeslider__handle-tooltip) {
    background-color: $blue;

    &:after {
      border-top-color: $blue;
    }
  }

  :global(.rangeslider__fill) {
    background-color: $blue;
    box-shadow: none;
  }

  :global(.rangeslider-horizontal) {
    background-color: $mediumGrey;
    box-shadow: none;
    height: 3px;
  }
}

.CostEstimator_contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @mixin above $medium {
    flex-direction: row;
  }

  > div {
    flex: 0 1 30%;
    margin: 1rem 0;

    @mixin above $medium {
      margin: 0;
    }
  }

  input {
    width: 100%;
  }
}

.CostEstimate_estimate {
  @mixin gordita-bold;
  color: $blue;
  font-size: 2.5rem;
  line-height: 1.1;
}
